import React, { useState, Fragment, useEffect } from "react";
import { Link, navigate } from "gatsby";
import Markdown from "react-markdown";
import defImage from "../images/service-default.jpg";


const SmallBlog = ({
  image,
  cloud_icons,
  isMobileView,
  blogDescription,
  blogData,
}) => {
  const [selectedCard, setSelectedCard] = useState(0);
  useEffect(()=>{
    const path = window.location.pathname
    const result = blogData.titles.findIndex((title)=>{
      return title.content.find((content)=>{
        return path.includes(content.link)
      })
    })

    if(result > -1){
      setSelectedCard(result)
    }else{
      setSelectedCard(0)
    }
  }, [])
  
  return (
    <div className="ouraprchSec pt-5" id="successstories">
      <div className="ourappWrap pt-0">
        <div
          className={`${
            isMobileView ? "successStoriesImages" : "successStoriesImages row"
          }`}
        >
          <div className={` ${isMobileView ? "col-12" : "col-md-8"}`}>
            <div
              className={` ${
                isMobileView
                  ? "font-size-14 line-height-20 font-weight-400 whoarewe-color-text roboto"
                  : "empowering-text whoarewe-color-text pb-3 roboto"
              }`}
            >
              <h2
                className={` ${
                  isMobileView
                    ? "font-size-14 line-height-20 font-weight-400 whoarewe-color-text pb-3 roboto"
                    : "empowering-text whoarewe-color-text pb-3 roboto"
                }`}
              >
                {blogDescription}
              </h2>
            </div>
            <div
              className={` ${
                isMobileView
                  ? "font-size-14 line-height-20 font-weight-400 text-light open-sans "
                  : "progress-text text-light open-sans "
              }`}
            >
              <Markdown>{blogData.description}</Markdown>
            </div>
          </div>
          <div
            className={` ${isMobileView ? "col-12" : "col-4 text-end d-flex"}`}
          >
            <img
              src={image || defImage}
              alt="Background"
              width="100%"
              height="100%"
              loading="lazy"
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>

        <div id={selectedCard} style={{ height: "60px" }} />

        <div className="row">
          <div
            className={`${
              isMobileView ? "col-12 d-flex flex-wrap gap-4" : "col-3 pt-4"
            }`}
          >
            {blogData.titles.map((accordion, index) => (
              <div
                role="link"
                key={`accordion-up-${index}`}
                className={`${
                  isMobileView
                    ? "font-size-14 line-height-20 font-weight-400 clickable-title  pb-4 line-height-25 "
                    : " whoarewe-title clickable-title home-solution-page line-height-25 "
                }${selectedCard === index ? "green-color" : "text-light "} ${
                  index === 0 && !isMobileView && "mt-4"
                }
                `}
                onClick={() => {
                  setSelectedCard(index);
                  if (index === 6) {
                    navigate(`/contact-us`);
                    return;
                  }
                  navigate(`#${accordion.title.replace(/\s/g, '_').toLowerCase()}`);
                }}
              >
                {accordion.title}
              </div>
            ))}
          </div>
          <div
            className={` ${isMobileView ? "col-12" : "col-9 blogContainer"}`}
          >
            {blogData.titles.map((accordion, index) => (
              <>
                {index === selectedCard && (
                  <>
                    <div
                      key={`accord-${index}`}
                      className={` ${
                        isMobileView
                          ? "my-4 taperzoid font-size-12 line-height-20 font-weight-400 mb-0 open-sans text-uppercase mb-0 ms-5"
                          : "my-4 taperzoid small-blog-title mb-0  text-uppercase mb-0 ms-5"
                      }`}
                    >
                      {accordion.title}
                    </div>
                    <div className="blog p-4 row">
                      {accordion.content.map((data, contentIndex) => (
                        <Fragment key={data.id}>
                          {index === selectedCard && (
                            <Fragment key={data.id}>
                              {data.display === "block" && (
                                <>
                                  <div
                                    className="home-blog-header text-light  blog-block"
                                    dangerouslySetInnerHTML={{
                                      __html: data.description1,
                                    }}
                                  />

                                  {data.link_text && (
                                    <div className="home-page-explore-now text-end green-color text-decoration-underline my-3"></div>
                                  )}
                                </>
                              )}
                              {data.display === "column" && (
                                <div
                                  className={` ${
                                    isMobileView ? "col-12" : "col-6 py-2"
                                  }`}
                                >
                                  <Link
                                    to={`/${data.link}`}
                                    className="text-decoration-none"
                                  >
                                    <div className="home-blog-title whoarewe-color-text  ">
                                      {data.card_title}
                                    </div>
                                  </Link>
                                  <p className="text-light home-blog-header ">
                                    {data.description1}
                                  </p>

                                  {cloud_icons && selectedCard === 5 ? (
                                    <div className="poisition my-1">
                                      {cloud_icons?.map(
                                        (icon, iconIndex) =>
                                          contentIndex - 1 === index && (
                                            <Link
                                              key={`link-icon-${iconIndex}`}
                                              to={
                                                iconIndex === 0
                                                  ? "/aws-cloud-platform"
                                                  : iconIndex === 1
                                                  ? "/azure-cloud-platform"
                                                  : "/gcp-cloud-platform"
                                              }
                                              className="text-decoration-none"
                                            >
                                              <img
                                                key={iconIndex}
                                                className="mx-1 cursor-pointer"
                                                src={icon.url}
                                                alt="icon"
                                                width={
                                                  iconIndex === 0 ? "8%" : "5%"
                                                }
                                                height="5%"
                                                loading="lazy"
                                              />
                                            </Link>
                                          )
                                      )}
                                    </div>
                                  ) : (
                                    <div className="poisition my-1">
                                      {cloud_icons?.map((icon, iconIndex) => (
                                        <Link
                                          key={`link-icon-${iconIndex}`}
                                          to={
                                            iconIndex === 0
                                              ? "/aws-cloud-platform"
                                              : iconIndex === 1
                                              ? "/azure-cloud-platform"
                                              : "/gcp-cloud-platform"
                                          }
                                          className="text-decoration-none"
                                        >
                                          <img
                                            key={iconIndex}
                                            className="mx-1 cursor-pointer"
                                            src={icon.url}
                                            alt="icon"
                                            width={
                                              iconIndex === 0 ? "8%" : "5%"
                                            }
                                            height="5%"
                                            loading="lazy"
                                          />
                                        </Link>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SmallBlog;
