import React from "react";

const Approach = ({
  title,
  description,
  cards,
  isMobileView,
  locale = "en",
}) => {
  return (
    <div className="background-color">
      <div className="global-margin">
        <div
          className={` ${isMobileView ? " " : "margin-top-botton py-4 mb-0"}`}
        >
          <h2
            className={` ${
              isMobileView
                ? "font-size-20  light-blue-color light-height-20 font-weight-400   text-uppercase pt-3 "
                : "global-services-heading font-weight-700  font-size-24 open-sans-bold text-uppercase pt-5 pb-3 "
            }`}
          >
            {title}
          </h2>
          <span
            className={`global-services-desc text-light ${
              locale !== "sv" && "capitalize"
            }`}
          >
            {description}
          </span>
          <div className={`row py-5 ${isMobileView ? "flex-column" : ""}`}>
            {cards?.map((card) => (
              <div
                className={`col-md-4 mb-5 ${
                  isMobileView ? "col-12 mt-2" : ""
                } global-services-box-bg`}
                key={card.id}
              >
                <div className="text-light title roboto-medium mb-4">
                  {card.card_title}
                </div>
                <p
                  className={`text-light global-services-desc ${
                    locale !== "sv" && "capitalize"
                  }`}
                  dangerouslySetInnerHTML={{ __html: card.card_description }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;
