import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import heroBg from "../../images/service-bg.webp";

const Heading = ({
  title,
  heading1,
  heading2,
  description,
  btn,
  isMobileView,
  locale = "en",
}) => {
  return (
    <>
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: `
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${process.env.GATSBY_ROOT_URL}/${title}"
              },
              "headline": "${heading1}",
              "description": "${description}",
              "author": {
                "@type": "Person",
                "name": "${"Opsio Admin"}"
              },
              "publisher": {
                "@type": "Organization",
                "name": "Opsio",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${
                    process.env.GATSBY_ROOT_URL
                  }/static/signature-logo.png"
                }
              },
              "datePublished": "2024-05-31",
              "articleBody": "${heading2}"
            }
            `,
          },
        ]}
      />

      <div className="service_upper_class">
        <div className="serviceHeroSection" id="heading">
          <img
            className="bg-image"
            alt="hero-bg"
            src={heroBg}
            width="100%"
            height="auto"
            loading="lazy"
          />
          <div className="service-containerwrap">
            <h4 className="serviceHeroSectionTitle  ">{title}</h4>
            <div className="deveps-service">
              <h1
                className={` ${
                  isMobileView
                    ? `font-size-20 text-white light-height-20 font-weight-400 roboto ${
                        locale !== "sv" && "text-capitalize"
                      } text-center`
                    : `serviceHeroSectionHeading font-weight-700  ${
                        locale !== "sv" && "text-capitalize"
                      } py-2`
                }`}
              >
                <span className="me-1">
                  {heading1} {heading2}
                </span>
              </h1>
            </div>

            <div
              className="serviceHeroSectionDescription open-sans"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className="opsBtnwrap middelBtn text-capitalize">
              <Link className="opsBtnlink" to={`/contact-us/#contact_form`}>
                <p>{btn}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Heading;
