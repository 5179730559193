import React, { useState } from "react";

import one from "../../images/01.svg";
import blueOne from "../../images/blue-one.svg";
import two from "../../images/02.svg";
import blueTwo from "../../images/blue-two.png";
import three from "../../images/03.svg";
import blueThree from "../../images/blue-three.png";
import four from "../../images/04.svg";
import blueFour from "../../images/blue-four.png";
import five from "../../images/05.svg";
import blueFive from "../../images/blue-five.png";
import six from "../../images/06.svg";
import blueSix from "../../images/blue-six.png";
import { Link } from "gatsby";

const Roadmap = ({ title, btn, isMobileView, plans }) => {
  const [active, setActive] = useState(1);

  return (
    <div className="row background-color">
      <div className="global-margin">
        <h2
          className={`${
            isMobileView
              ? "col-12 text-center"
              : "global-services-title global-title-color  roboto mb-md-5"
          }`}
        >
          {title}
        </h2>
        <div
          className={`d-flex ${
            isMobileView ? "col-12 mt-2" : "justify-content-between"
          } p-4`}
        >
          <div className="d-md-flex  col-md-12 w-72">
            {plans.map((plan) => (
              <>
                {plan.id <= 4 && (
                  <div className={`${plan.id === 4 ? "col-md-1" : "col-md-3"}`}>
                    {plan.id === 1 ? (
                      <img
                        src={active === plan.id ? one : blueOne}
                        width={75}
                        height={50}
                        alt="one"
                      />
                    ) : plan.id === 2 ? (
                      <img
                        className="me-3"
                        src={active === plan.id ? two : blueTwo}
                        width={75}
                        alt="two"
                      />
                    ) : plan.id === 3 ? (
                      <img
                        className="me-5"
                        src={active === plan.id ? three : blueThree}
                        width={75}
                        alt="three"
                      />
                    ) : plan.id === 4 ? (
                      <img
                        src={active === plan.id ? four : blueFour}
                        width={75}
                        alt="four"
                      />
                    ) : null}
                  </div>
                )}
              </>
            ))}
          </div>
          <div className="d-md-flex  col-md-12 w-39">
            {plans.map((plan) => (
              <>
                {plan.id >= 5 && (
                  <div className="col-md-6">
                    {plan.id === 5 && (
                      <img
                        className="me-3"
                        src={active === 5 || active === 6 ? five : blueFive}
                        width={75}
                        alt="five"
                      />
                    )}
                    {plan.id === 6 && (
                      <img
                        src={active === 6 || active === 5 ? six : blueSix}
                        width={75}
                        alt="six"
                      />
                    )}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div
          className={`d-flex ${isMobileView ? " col-12 mt-2" : ""}  pt-5 pb-3`}
        >
          {plans.map((plan, index) => (
            <>
              {index < 4 && (
                <div
                  className={`text-light text-center px-5 h-100  py-1 ${
                    plan.id === active ? "plan-active" : "progress"
                  }`}
                  role="button"
                  onClick={() => setActive(plan.id)}
                >
                  {plan.planType}
                </div>
              )}
              {index > 4 && (
                <div
                  className="text-light text-center px-l-r progress1 h-100  mb-1 "
                  role="button"
                  onClick={() => setActive(plan.id)}
                >
                  {plan.planType}
                </div>
              )}

              {plans.length !== index + 1 && plan.id !== 5 && (
                <hr class="new1"></hr>
              )}
            </>
          ))}
        </div>
        <div className="row">
          <div
            className={`d-md-flex justify-content-md-between ${
              isMobileView ? "col-12 mt-2" : "col-md-12"
            } `}
          >
            {plans.map((plan, index) => (
              <div
                className={`d-flex flex-column w-16  ${
                  plan.id === plans.length ? "me-0" : "margin-right-for-devops"
                }`}
              >
                <div
                  className={`font-weight-400  text-light service-header margin-bottom-for-devops`}
                >
                  {plan.plan_title}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-md-flex justify-content-md-between col-md-12 w-100">
          {plans.map((plan, index) => (
            <div
              className={`d-flex flex-column w-22 ${
                plan.id === plans.length ? "me-0" : "margin-right1-for-devops"
              }`}
            >
              {plan.plan_description.map((description) => (
                <span
                  className="text-light progress-text roadmap mb-3"
                  key={description.id}
                >
                  <span
                    className={`text-light mt-5 w-16 font-weight-400  font-size-20 line-height-30 `}
                  >
                    {description.content}
                  </span>
                </span>
              ))}
            </div>
          ))}
        </div>
        <div className="text-center margin-top-botton">
          <Link
            to={`/contact-us/`}
            type="submit"
            className="src_btn text-decoration-none btm-width"
          >
            {btn}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
