import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layoutnew";
import SEO from "../components/seo";
import "../styles/newServices.scss";
import Heading from "../components/newServices/Heading";
import Concept from "../components/newServices/concept";
import Approach from "../components/newServices/approach";
import Roadmap from "../components/newServices/roadmap";
import Blog from "../components/smallBlog";
import Features from "../components/newServices/features";
import Faq from "../components/newServices/faq";

const NewServices = ({ data }) => {
  const getNewServicesData = (data) => {
    const values = data.allStrapiNewService.edges[0]?.node;
    return values;
  };

  const getServiceData = data.allStrapiService.edges[0]?.node;
  const getIndexData = data.allStrapiIndexpage.edges[0]?.node;

  const getData = getNewServicesData(data);

  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth <= 1100);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
        description={getData.metaDes}
        slug={getData.slug}
        locale={getData.locale}
        title={getData.metaTitle}
        keywords={getData.metaKeywords}
      />

      <Heading
        title={getData.title}
        heading1={getData.heading1}
        heading2={getData.heading2}
        description={getData.description.data.description}
        btn={getData.buttonText}
        isMobileView={isMobileOrTablet}
        locale={getData.locale}
      />
      <Concept
        overviewLeftTitle={getData.overview_left_section_title}
        overviewLeftDesc={
          getData.overview_left_section.data.overview_left_section
        }
        overviewRightTitle={getData.overview_right_section_title}
        overviewRightDesc={
          getData.overview_right_section.data.overview_right_section
        }
        isMobileView={isMobileOrTablet}
      />

      <Approach
        title={getData.approach_title}
        description={getData.approach_description.data.approach_description}
        cards={getData.approach_cards?.cards}
        isMobileView={isMobileOrTablet}
        locale={getData.locale}
      />

      {!isMobileOrTablet && (
        <Roadmap
          title={getData.roadmap_title}
          plans={getServiceData.roadmap?.plans}
          btn={getData.roadmap_button_text}
          isMobileView={isMobileOrTablet}
        />
      )}

      <Features
        leftSectionTitle={getData.benefits_left_section_title}
        leftSection={getData.benefits_left_section.data.benefits_left_section}
        rightSectionTitle={getData.benefits_right_section_title}
        rightSection={
          getData.benefits_right_section.data.benefits_right_section
        }
        isMobileView={isMobileOrTablet}
      />
      <Blog
        title={getData.services_title}
        description={getData.services_description.data.services_description}
        image={getData.services_image?.url}
        accordionsFetch={getData.services_accordions?.titles}
        cloud_icons={getData.cloud_icons}
        isMobileView={isMobileOrTablet}
        blogDescription={getServiceData.smallBlog.description}
        blogData={getIndexData.smallBlogs}
      />
      <Faq
        title={getData.faqTitle}
        faqs={getData.faqs}
        isMobileView={isMobileOrTablet}
      />
    </Layout>
  );
};

export default NewServices;

export const query = graphql`
  query NewServices($locale: String!, $slug: String!) {
    allStrapiSocialFeed(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
          heading
          heading1
        }
      }
    }
    allStrapiService(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          smallBlog {
            description
          }
          roadmap {
            plans {
              id
              planType
              plan_description {
                content
                id
              }
              plan_title
            }
          }
        }
      }
    }
    allStrapiIndexpage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          smallBlogs {
            titles {
              title
              content {
                id
                display
                link_text
                card_title
                description1
                description2
                is_icons_required
                link
              }
            }
            description
          }
        }
      }
    }
    allStrapiNewService(
      filter: { locale: { eq: $locale }, slug: { eq: $slug } }
    ) {
      edges {
        node {
          locale
          slug
          metaTitle
          metaDes
          metaKeywords
          title
          heading1
          heading2
          description {
            data {
              description
            }
          }
          buttonText
          overview_left_section_title
          overview_left_section {
            data {
              overview_left_section
            }
          }
          overview_right_section_title
          overview_right_section {
            data {
              overview_right_section
            }
          }
          approach_title
          approach_description {
            data {
              approach_description
            }
          }
          approach_cards {
            cards {
              id
              card_title
              card_description
            }
          }
          services_title
          services_description {
            data {
              services_description
            }
          }
          services_image {
            url
          }
          services_accordions {
            titles {
              title
              id
              content {
                card_title
                description1
                description2
                display
                id
                link_text
              }
            }
          }
          roadmap_title
          roadmap_button_text
          roadmap_plans {
            plans {
              id
              planType
              plan_description {
                content
                id
              }
              plan_title
            }
          }
          benefits_left_section_title
          benefits_left_section {
            data {
              benefits_left_section
            }
          }
          benefits_right_section_title
          benefits_right_section {
            data {
              benefits_right_section
            }
          }
          cloud_icons {
            url
          }
          faqTitle
          faqs {
            question
            answer {
              data {
                answer
              }
            }
          }
        }
      }
    }
  }
`;
