import React from "react";
import Markdown from "react-markdown";

const Concept = ({
  overviewLeftTitle,
  overviewLeftDesc,
  overviewRightTitle,
  overviewRightDesc,
  isMobileView,
}) => {
  return (
    <div className="serviceHotspot position-relative" id="overview">
      <div className="global-margin">
        <div className="row margin-top-botton pt-5">
          <div
            className={`col-md-6 ${isMobileView ? "col-12 text-center" : ""}`}
          >
            <h2 className="global-services-title roboto">
              {overviewLeftTitle}
            </h2>
            <div className="global-services-desc ">
              <Markdown>{overviewLeftDesc}</Markdown>
            </div>
          </div>
          <div
            className={`col-md-6 ${isMobileView ? "col-12 text-center" : ""}`}
          >
            <h2 className="global-services-title roboto">
              {overviewRightTitle}
            </h2>
            <div className="global-services-desc ">
              <Markdown>{overviewRightDesc}</Markdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Concept;
